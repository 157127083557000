export const RESTORE_TOKEN = 'RESTORE_TOKEN';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const PROJECT_ACTIVE = 'PROJECT_ACTIVE';
export const PROJECT_CLOSE = 'PROJECT_CLOSE';
export const CONFIG = 'CONFIG';
export const CONFIG_SAVE = 'CONFIG_SAVE';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

